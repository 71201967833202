import _ from "lodash";
import es_events from "./events-es.json";
import en_events from "./events-en.json";
import fr_events from "./events-fr.json";
import it_events from "./events-it.json";

const byDefault = (obj) => {
  if (_.isEmpty(obj) || _.isNull(obj)) return en_events;
  if (_.isArray(obj) && _.some(obj, (o) => _.has(o, "attributes.category"))) {
    const current_categories = _.groupBy(obj, "attributes.category");
    const merge = Object.entries(_.groupBy(en_events, "attributes.category")).map(([key, value]) => _.get(current_categories, key, value));
    return merge.flat();
  }
  if (_.isObject(obj) && !_.isArray(obj)) {
    const isSingleType = _.get(obj, "attributes", null);
    if (isSingleType) return { ...en_events, ...obj };
    const result = Object.entries(en_events).reduce((acc, [key, values]) => {
      const translation = _.get(obj, key, null);
      if (translation) {
        const newAttributes = translation.attributes;
        Object.keys(newAttributes).forEach(
          (_key) => (newAttributes[_key] == null || (newAttributes[_key].data && _.isEmpty(newAttributes[_key].data)))
            && delete newAttributes[_key],
        );
        acc[key] = {
          ...values,
          attributes: { ...en_events[key].attributes, ...newAttributes },
        };
      } else {
        acc[key] = { ...values };
      }
      return acc;
    }, {});
    return result;
  }
  return obj;
};

const events = {
  en: en_events,
  es: byDefault(es_events),
  fr: byDefault(fr_events),
  it: byDefault(it_events),
};

export default events;
